<template>
    <div>
      <el-card>
        <div class="tool">
          <el-button type="primary" class="el-icon-plus" @click="AddClick"> 新增订单</el-button>
          <el-button type="danger" class="el-icon-delete" @click="DelClick"> 删除</el-button>

          <el-button type="primary" class="el-icon-tickets" @click="FreeMedia"> 空刊位</el-button>

          <el-input placeholder="请输入名称" v-model="searchForm.searchInfo" style="width:250px;margin-left:15px">
            <el-button slot="append" icon="el-icon-search" @click="Search"></el-button>
          </el-input>
        </div>
      </el-card>
      <el-card style="margin-top:15px">
        <el-menu default-active="0" background-color="#F7F7F7" mode="horizontal" @select="handleSelect">
          <el-menu-item index="0">全部订单</el-menu-item>
          <el-menu-item index="1">正式订单</el-menu-item>
          <el-menu-item index="2">预锁订单</el-menu-item>
          <el-menu-item index="3">预选订单</el-menu-item>
        </el-menu>

        <el-table :data='OrderListData' ref='orderTable' stripe :highlight-current-row='true' max-height='calc(100vh - 315px)' height='calc(100vh - 315px)'>
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column align="center" prop="order_name" label="名称"></el-table-column>
          <el-table-column align="center" prop="order_type" label="订单类型">
            <template slot-scope="scope">
               {{scope.row.order_type===0?'正式订单':(scope.row.order_type===1?"预锁订单":"预选订单")}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="order_apply_time" label="申请日期">
            <template slot-scope="scope">
             {{FormateData(scope.row.order_apply_time,2)}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="order_begin_time" label="开始日期">
            <template slot-scope="scope">
             {{FormateData(scope.row.order_begin_time,0)}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="order_end_time" label="结束日期">
             <template slot-scope="scope">
             {{FormateData(scope.row.order_end_time,0)}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="order_lock_time" label="锁定截止期">
             <template slot-scope="scope">
             {{scope.row.order_type===1?FormateData(scope.row.order_lock_time,0):''}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="ct_name" label="客户"></el-table-column>
          <el-table-column align="center" prop="sp_name" label="销售人员"></el-table-column>
          <el-table-column align="center" prop="price" label="价格(元)"></el-table-column>
          <el-table-column align="center" prop="order_state" label="当前状态">
            <template slot-scope="scope">
              <el-tag :type="scope.row.order_state===0?'info':(scope.row.order_state===1?'warning':(scope.row.order_state===2?'success':(scope.row.order_state===3?'#409EFF':'#A0CFFF')))">
                {{scope.row.order_state===0?'待选点位':(scope.row.order_state===1?"待投放":(scope.row.order_state===2?"投放中":(scope.row.order_state===3?"已完成":'锁定过期')))}}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="order_name" label="操作">
            <template slot-scope="scope">
              <el-link type="primary" @click="Edit(scope.row)">修改</el-link>
              <el-link type="primary" @click="Apply(scope.row)">广告投放</el-link>
              <el-link type="primary" @click="MediaDetail(scope.row)">点位信息</el-link>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="fy" layout="prev, pager, next" @current-change="current_change" :total="searchForm.total" background :page-size="searchForm.pageSize"></el-pagination>
      </el-card>

      <!--申请单-->
      <el-dialog :title="title" :visible.sync="dialogVisible" width='50%' :append-to-body='true'>
          <el-form :model="orderForm" :rules="rules" ref="orderForm" label-width="100px" size='medium'>
                <el-form-item label="名称" prop="order_name">
                    <el-input v-model="orderForm.order_name"></el-input>
                </el-form-item>
                <el-form-item label="订单类型">
                  <div style="text-align:left">
                    <el-radio v-model="orderForm.order_type" :label="0">正式订单</el-radio>
                    <el-radio v-model="orderForm.order_type" :label="1" v-if="orderForm.order_id===0||(orderForm.order_id>0&&orderForm.order_type!==0)" >预锁订单</el-radio>
                    <el-radio v-model="orderForm.order_type" :label="2" v-if="orderForm.order_id===0||(orderForm.order_id>0&&orderForm.order_type!==0)" >预选订单</el-radio>
                  </div>
                </el-form-item>
                <el-form-item label="开始日期"  prop="order_begin_time">
                    <el-date-picker  v-model="orderForm.order_begin_time" type="date" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd" style="width:100%"></el-date-picker>
                </el-form-item>
                <el-form-item label="结束日期" prop="order_end_time">
                   <el-date-picker  v-model="orderForm.order_end_time" type="date" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd" style="width:100%"></el-date-picker>
                </el-form-item>
                <el-form-item label="锁定截止日期" prop="order_lock_time" v-if="orderForm.order_type===1">
                   <el-date-picker v-model="orderForm.order_lock_time" type="date" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd" style="width:100%"></el-date-picker>
                </el-form-item>
                <el-form-item label="客户" prop="order_customer_id">
                    <el-select v-model="orderForm.order_customer_id" style="width:100%;">
                      <el-option v-for="item in CustomerDataList" :key="item.ct_id" :value="item.ct_id" :label="item.ct_name" ></el-option>
                      <el-option :value="0" label=" "></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="销售员" prop="order_salesper_id">
                    <el-select v-model="orderForm.order_salesper_id" style="width:100%;">
                      <el-option v-for="item in SPDataList" :key="item.user_id" :value="item.user_id" :label="item.user_name" ></el-option>
                      <el-option :value="0" label=" "></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="合同签订期" v-if="orderForm.order_type===0">
                  <el-date-picker v-model="orderForm.order_contract_time" type="date" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd" style="width:100%"></el-date-picker>
                </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
          </span>
      </el-dialog>

      <!--广告发布-->
      <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="mediaDialogVisible" :append-to-body='true' fullscreen>
         <el-form :model="applyForm" :rules="rules" ref="applyForm" label-width="100px" size='medium'>
            <el-form-item label="订单名称">
                <el-input v-model="OrderRow.order_name" :disabled="true"></el-input>
            </el-form-item>
           <el-form-item label="点位包">
                <el-select  v-model="mediaSearchForm.mp_id" style="width:200px;float:left" @change="TypeChanged($event,0)">
                    <el-option :value="0" :key="0" label="全部"></el-option>
                    <el-option v-for="item in PackageDataList" :key="item.mp_id" :value="item.mp_id" :label="item.mp_name"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="选点方式">
                <el-select  v-model="mediaSearchForm.check_type" style="width:200px;float:left" @change="TypeChanged($event,1)">
                    <el-option :value="0" label="请选择"></el-option>
                    <el-option :value="1" label="整体选点"></el-option>
                    <el-option :value="2" label="手动选择"></el-option>
                    <el-option :value="3" label="快捷选择"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="点位信息" v-if="mediaSearchForm.check_type===2">
                <el-input v-model="mediaSearchForm.mediaInfo"  placeholder="点位名称或编号" style="width:400px;float:left">
                    <el-select slot="prepend" v-model="mediaSearchForm.media_show_type" style="width:150px;">
                        <el-option label="全部" :value='-1'></el-option>
                        <el-option label="灯箱广告" :value='0'></el-option>
                        <el-option label="道闸广告" :value='1'></el-option>
                        <el-option label="电梯框架" :value='2'></el-option>
                        <el-option label="广告看板" :value='3'></el-option>
                        <el-option label="屏蔽门" :value='4'></el-option>
                        <el-option label="候车亭广告" :value='5'></el-option>
                        <el-option label="车身广告" :value='6'></el-option>
                        <el-option label="其他" :value='7'></el-option>
                    </el-select>
                    <el-button slot="append" icon="el-icon-search" @click="SelectMedia"></el-button>
                  </el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="applyForm.remark"></el-input>
            </el-form-item>

            <el-card>
                <el-row :gutter="10">
                  <el-col :xs="24" :sm="24" :md="mediaSearchForm.check_type===1?24:12">
                     <el-table :data="SearchResultDataList" ref='searchTable' stripe :highlight-current-row='true' size="mini" @select="Checked" @select-all='SelectAll'  :key='mediaSearchForm.check_type' >
                          <el-table-column v-if="mediaSearchForm.check_type===1||mediaSearchForm.check_type===2" align="center" type="selection" width="50"></el-table-column>
                          <el-table-column v-if="mediaSearchForm.check_type===1"  align="center" prop="total" label="点位数量"></el-table-column>
                          <el-table-column v-if="mediaSearchForm.check_type===1"  align="center" prop="use" label="已用点位数"></el-table-column>
                          <el-table-column v-if="mediaSearchForm.check_type===1"  align="center" prop="free" label="可用点位数"></el-table-column>

                          <el-table-column v-if="mediaSearchForm.check_type===2"  align="center" prop="media_no" label="点位编号"></el-table-column>
                          <el-table-column v-if="mediaSearchForm.check_type===2" align="center" prop="media_name"  label="点位名称"></el-table-column>
                          <el-table-column v-if="mediaSearchForm.check_type===2||mediaSearchForm.check_type===3" align="center" prop="media_community_addr" label="媒体地址"></el-table-column>
                          <el-table-column v-if="mediaSearchForm.check_type===2" align="center" prop="media_show_type" label="点位类型">
                            <template slot-scope="slot">
                              {{slot.row.media_show_type===0?'灯箱广告':(slot.row.media_show_type===1?'道闸广告':(slot.row.media_show_type===2?'电梯框架':slot.row.media_show_type===3?'广告看板':(
                                slot.row.media_show_type===4?'屏蔽门':(slot.row.media_show_type===5?'候车亭广告':(slot.row.media_show_type===6?'车身广告':'其他'))
                              )))}}
                            </template>
                          </el-table-column>
                          <el-table-column v-if="mediaSearchForm.check_type===2" align="center" prop="media_addr" label="点位位置"></el-table-column>

                        <el-table-column v-if="mediaSearchForm.check_type===3" align="center" prop="totalnum" label="选择点位数">
                          <template slot-scope="slot">
                            <el-select v-model="slot.row.checknum" @change="MediaNumChanged($event,slot.row.media_community_addr)">
                              <el-option :key="0" :value="0" label="0 个"></el-option>
                              <el-option v-for="index of slot.row.freenum" :key="index" :value="index" :label="index+' 个'"></el-option>
                            </el-select>
                          </template>
                        </el-table-column>
                        <el-table-column v-if="mediaSearchForm.check_type===3" align="center" prop="totalnum" label="总点位数"></el-table-column>
                        <el-table-column v-if="mediaSearchForm.check_type===3" align="center" prop="freenum" label="可用点位数">
                            <template slot-scope="slot">
                              {{slot.row.freenum===null?0:slot.row.freenum}}
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination v-if="mediaSearchForm.check_type===2" class="fy" layout="prev, pager, next" @current-change="currentChange" :total="mediaSearchForm.total" background :page-size="mediaSearchForm.pageSize"></el-pagination>
                  </el-col>
                  <el-col v-if="mediaSearchForm.check_type!==1" :xs="24" :sm="24" :md="mediaSearchForm.check_type===1?24:12">
                      <el-table :data="applyForm.checkedMediaDataList" ref='checkTable' stripe :highlight-current-row='true' size="mini" :key='mediaSearchForm.check_type' >
                          <el-table-column v-if="mediaSearchForm.check_type===1||mediaSearchForm.check_type===2" type="selection" width="50"></el-table-column>
                          <el-table-column v-if="mediaSearchForm.check_type===1"  align="center" prop="total" label="点位数量"></el-table-column>
                          <el-table-column v-if="mediaSearchForm.check_type===1"  align="center" prop="use" label="已用点位数"></el-table-column>
                          <el-table-column v-if="mediaSearchForm.check_type===1"  align="center" prop="free" label="可用点位数"></el-table-column>

                          <el-table-column v-if="mediaSearchForm.check_type===2"  align="center" prop="media_no" label="点位编号"></el-table-column>
                          <el-table-column v-if="mediaSearchForm.check_type===2" align="center" prop="media_name"  label="点位名称"></el-table-column>
                          <el-table-column v-if="mediaSearchForm.check_type===2||mediaSearchForm.check_type===3" align="center" prop="media_community_addr" label="媒体地址"></el-table-column>
                          <el-table-column v-if="mediaSearchForm.check_type===2" align="center" prop="media_show_type" label="点位类型">
                            <template slot-scope="slot">
                              {{slot.row.media_show_type===0?'灯箱广告':(slot.row.media_show_type===1?'道闸广告':(slot.row.media_show_type===2?'电梯框架':slot.row.media_show_type===3?'广告看板':(
                                slot.row.media_show_type===4?'屏蔽门':(slot.row.media_show_type===5?'候车亭广告':(slot.row.media_show_type===6?'车身广告':'其他'))
                              )))}}
                            </template>
                          </el-table-column>
                          <el-table-column v-if="mediaSearchForm.check_type===2" align="center" prop="media_addr" label="点位位置"></el-table-column>

                        <el-table-column v-if="mediaSearchForm.check_type===3" align="center" prop="num" label="选择点位数"></el-table-column>
                        <el-table-column align="center" prop="order_name" label="操作">
                          <template slot-scope="scope">
                            <el-link type="primary" @click="DelChecked(scope.row)">删除</el-link>
                          </template>
                        </el-table-column>
                    </el-table>
                  </el-col>
                </el-row>

            </el-card>
         </el-form>
          <span slot="footer" class="dialog-footer">
                <el-button @click="mediaDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="Save">确 定</el-button>
          </span>
      </el-dialog>

      <!--订单详情-->
      <el-dialog v-if="orderDetailDialogVisible" :title="title" :visible.sync="orderDetailDialogVisible" :width='screenWidth>1400?"60%":"70%"' :append-to-body='true'>
        <OrderDetail :orderId="OrderDetailId" :orderName="OrderName" :showDel="showDel"></OrderDetail>
        <span slot="footer" class="dialog-footer">
          <el-button @click="orderDetailDialogVisible = false">取 消</el-button>
        </span>
     </el-dialog>
  </div>
</template>

<script>
import util from '../../util/util'
import OrderDetail from '../../components/media/OrderDetail.vue' 



export default {
  data: function () {
    return {
      User:null,
      screenWidth:0,
      searchForm: {
        searchInfo: '',
        orderState: 0,
        pageSize: 20,
        pageNo: 1,
        total: 0
      },
      cityDataList: {},
      cityList: {},
      countyList: {},
      SPDataList: [], // 销售
      ischange: false,
      CustomerDataList: [], // 客户
      OrderListData: [],
      title: '',
      dialogVisible: false,
      orderForm: {
        order_id: 0,
        order_name: '',
        order_type: 0,
        order_begin_time: new Date(),
        order_end_time: new Date(),
        order_lock_time: new Date(),
        order_customer_id: 0,
        order_salesper_id: 0,
        order_contract_time: new Date()
      },

      mediaDialogVisible: false,
      PackageDataList: [], // 点位包

      // 查询条件
      mediaSearchForm: {
        order_id: 0,
        mp_id: 0,
        check_type: 0,
        mediaInfo: '',
        media_show_type: -1,
        pageSize: 20,
        pageNo: 1,
        total: 0
      },

      SearchResultDataList: [], // 查询结果

      OrderRow: {},

      // 广告投放
      applyForm: {
        order_id: 0,
        remark: '',

        checkedMediaDataList: [] // 选择的点位数据
      },

      companyDialogVisible: false,


      orderDetailDialogVisible:false,
      OrderDetailId:0,
      OrderName:'',
      showDel:false,

      rules: {
        order_name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        order_begin_time: [{ required: true, message: '请输入起始日期', trigger: 'blur' }],
        order_end_time: [{ required: true, message: '请输入截止日期', trigger: 'blur' }],
        pc_name: [{ required: true, message: '请输入公司名称', trigger: 'blur' }]
      }
    }
  },
  components: {
    OrderDetail
  },
  computed: {
    FormateData () {
      return function (time, type) {
        return util.FormateDate(time, type)
      }
    },
    CheckProvice () {
      return function (row, state) {
        var name = ''
        if (row.media_province !== null && row.media_province !== undefined) {
          if (state === 0) { name = this.cityDataList.province_list[row.media_province] }
          if (state === 1) {
            name = this.cityDataList.city_list[row.media_city]
          }
          if (state === 2) {
            name = this.cityDataList.county_list[row.media_county]
          }
        }
        return name
      }
    }
  },
  created () {
    this.screenWidth = document.body.clientWidth
    this.User=util.JesToken()
    this.GetOrderDataList()
    util.Get('user/getuserdatalist?userType=3&id='+this.User.user_sp_id).then(res=>{
          if (res.rpStatus === 10000) {
          this.SPDataList = res.list
        } else {
          this.$message.error(res.rpMsg)
        }
    })
    util.Get('customer/GetCustomerDataList').then(res => {
      if (res.rpStatus === 10000) {
        this.CustomerDataList = res.list
      }
    })
    this.cityDataList = require('../../util/area.json')
  },
  methods: {
    GetOrderDataList () {
      util.Get('applyorder/getapplyorderdatalist', this.searchForm).then(res => {
        if (res.rpStatus === 10000) {
          this.OrderListData = res.list
          this.searchForm.total = res.total
          
        }
      })
    },
    Search () {
      this.GetOrderDataList()
    },
    current_change (page) {
      this.searchForm.pageNo = page
      this.GetOrderDataList()
    },
    currentChange (page) {
      this.mediaSearchForm.pageNo = page
      this.SearchMedia()
    },
    handleSelect (key, keyPath) {
      this.searchForm.orderState = key
      this.GetOrderDataList()
    },
    AddClick () {
      this.dialogVisible = true
      this.title = '新增订单'
      this.orderForm.order_id = 0
      this.orderForm.order_name = ''
      this.orderForm.order_type = 0
      this.orderForm.order_begin_time = new Date()
      this.orderForm.order_end_time = new Date()
      var time = new Date()
      time.setDate(time.getDate() + 7)
      this.orderForm.order_lock_time = new Date(time)
      this.orderForm.order_customer_id = 0
      this.orderForm.order_salesper_id = 0
      this.orderForm.order_contract_time = new Date()
    },
    Edit (row) {
      this.dialogVisible = true
      this.title = '修改申请单'
      this.orderForm = JSON.parse(JSON.stringify(row))
      this.orderForm.order_begin_time = new Date(row.order_begin_time)
      this.orderForm.order_end_time = new Date(row.order_end_time)
      this.orderForm.order_lock_time = new Date(row.order_lock_time)
      this.orderForm.order_contract_time = new Date(row.order_contract_time)
    },
    submit () {
      if (this.orderForm.order_type === 1) {
        var t1 = new Date(this.orderForm.order_lock_time)
        var t2 = new Date(this.orderForm.order_end_time)
        if (t2 < t1) {
          this.$message.error('锁定日期不能大于订单有效期')
          return
        }
      }

      this.$refs.orderForm.validate((valid) => {
        if (valid) {
          util.Post('applyorder/edit', this.orderForm).then(res => {
            if (res.rpStatus === 10000) {
              this.GetOrderDataList()
              this.dialogVisible = false
            } else { this.$message.error(res.rpMsg) }
          })
        }
      })
    },
    DelClick () {
      var list = this.$refs.orderTable.selection
      if (list.length > 0) {
        this.$confirm('确定删除选择的申请单？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          util.Post('applyorder/del', list).then(res => {
            if (res.rpStatus === 10000) { this.GetOrderDataList() } else { this.$message.error(res.rpMsg) }
          })
        }).catch(e => e)
      } else {
        this.$message.error('请勾选要删除的订单')
      }
    },

    //空刊
    FreeMedia(){
      this.orderDetailDialogVisible=true
      this.title='空刊位'
      this.OrderDetailId=-1
      this.OrderName=''
      this.showDel=false
    },

    // 点位申请
    Apply (row) {
      if(row.order_type===1){
        if(new Date(row.order_lock_time)<=new Date()){
          this.$message.error('已超出锁定有效期')
          return
        }
      }
      else{
        if(new Date(row.order_end_time)<=new Date()){
          this.$message.error('订单已结束')
          return
        }
      }
      this.mediaDialogVisible = true
      this.title = '广告投放'
      this.OrderRow = row

      if (this.PackageDataList.length === 0) {
        util.Get('media/GetPackageDataList').then(res => {
          if (res.rpStatus === 10000) {
            this.PackageDataList = res.list
          } else { this.$message.error(res.rpMsg) }
        })
      }

      this.mediaSearchForm.order_id = row.order_id
      this.mediaSearchForm.mp_id = 0
      this.mediaSearchForm.check_type = 0
      this.mediaSearchForm.mediaInfo = ''
      this.mediaSearchForm.media_show_type = -1
      this.mediaSearchForm.pageSize = 20
      this.mediaSearchForm.pageNo = 1
      this.mediaSearchForm.total = 0
      this.SearchResultDataList = []
      this.applyForm.checkedMediaDataList = []
    },
    TypeChanged (value, source) {
      if (source === 0) {
        this.mediaSearchForm.search_type = value
      }
      if (source === 1) {
        this.mediaSearchForm.check_type = value
      }
      this.SearchResultDataList = []
      this.applyForm.checkedMediaDataList = []
      if (this.mediaSearchForm.check_type !== 2) { this.SearchMedia() }
    },
    SelectMedia () {
      this.mediaSearchForm.pageNo = 1
      this.mediaSearchForm.pageSize = 20
      this.mediaSearchForm.total = 0
      this.SearchMedia()
    },
    SearchMedia () {
      if (this.mediaSearchForm.check_type !== 0) {
        util.Post('applyorder/GetMediaPlan', this.mediaSearchForm).then(res => {
          if (this.mediaSearchForm.check_type === 1) {
            if (res.rpStatus === 10000) {
              var form = {
                total: res.list[1],
                free: res.list[0],
                use: res.list[1] - res.list[0]
              }
              this.SearchResultDataList.push(form)
            }
          } else {
            if (res.rpStatus === 10000) {
              this.mediaSearchForm.total = res.total
              this.SearchResultDataList = res.list
              if (res.total === 0) {
                this.$message.error('无可用的点位')
              }
            }
          }
        })
      }
    },

    //点位详情
    MediaDetail(row){
      this.orderDetailDialogVisible=true
      this.title='点位详情'
      this.OrderDetailId=row.order_id
      this.OrderName=row.order_name
      this.showDel=row.order_type===0?false:true
    },

    Checked (selection, row) {
      if (this.mediaSearchForm.check_type === 1) {
        if (selection.length > 0) {
          this.applyForm.checkedMediaDataList.push({
            ischecked: true
          })
        } else { this.applyForm.checkedMediaDataList = [] }
      }
      if (this.mediaSearchForm.check_type === 2) {
        var arr = selection.filter(a => a.media_id === row.media_id)
        if (arr.length > 0) {
          this.applyForm.checkedMediaDataList.push(row)
        } else {
          var index = this.applyForm.checkedMediaDataList.findIndex(a => a.media_id === row.media_id)
          this.$delete(this.applyForm.checkedMediaDataList, index)
        }
      }
    },
    SelectAll(selections){
      this.applyForm.checkedMediaDataList = [] 
      if(selections.length>0){
        if (this.mediaSearchForm.check_type === 1) {
          this.applyForm.checkedMediaDataList.push({
            ischecked: true
          })
        }
        if (this.mediaSearchForm.check_type === 2) {
          selections.forEach(item=>{
            this.applyForm.checkedMediaDataList.push(item)
          })
        }
      }
    },
    MediaNumChanged (num, name) {
      var arr = this.applyForm.checkedMediaDataList.filter(a => a.media_community_addr === name)
      if (num === 0 && arr.length > 0) {
        var index = this.applyForm.checkedMediaDataList.findIndex(a => a.media_community_addr === name)
        this.$delete(this.applyForm.checkedMediaDataList, index)
      }
      if (num > 0) {
        if (arr.length === 0) {
          this.applyForm.checkedMediaDataList.push({
            media_community_addr: name,
            num: num
          })
        } else {
          arr[0].num = num
        }
      }
    },
    DelChecked (row) {
      var index = -1
      if (this.mediaSearchForm.check_type === 2) {
        index = this.applyForm.checkedMediaDataList.findIndex(a => a.media_id === row.media_id)
      }
      if (this.mediaSearchForm.check_type === 3) {
        index = this.applyForm.checkedMediaDataList.findIndex(a => a.media_community_addr === row.media_community_addr)
      }

      this.$delete(this.applyForm.checkedMediaDataList, index)
    },
    Save () {
      this.applyForm.order_id = this.OrderRow.order_id
      this.applyForm.check_type = this.mediaSearchForm.check_type
      this.applyForm.mp_id = this.mediaSearchForm.mp_id
      if (this.applyForm.checkedMediaDataList.length === 0) {
        this.$message.error('请选择投放的点位')
      }
      console.log(this.applyForm)
      util.Post('applyorder/publish', this.applyForm).then(res => {
        if (res.rpStatus === 10000) {
          this.mediaDialogVisible = false
          this.GetOrderDataList()
        } else {
          this.$message.error(res.rpMsg)
        }
      })
    }
  },
  watch: {
    'applyForm.province': function (newId) {
      this.cityList = { 0: '请选择' }
      this.countyList = { 0: '请选择' }
      var id = parseInt(newId)
      var end = id + 9900
      var start = id + 100
      for (var key in this.cityDataList.city_list) {
        var k = parseInt(key)
        if (k >= start && k <= end) {
          this.cityList[key] = this.cityDataList.city_list[key]
        }
      }
      if (this.ischange) {
        this.mediaForm.media_city = '0'
      }
    },
    'applyForm.city': function (newId) {
      this.countyList = { 0: '请选择' }

      var id = parseInt(newId)
      var end = id + 99
      var start = id + 1

      for (var key in this.cityDataList.county_list) {
        var k = parseInt(key)
        if (k >= start && k <= end) {
          this.countyList[key] = this.cityDataList.county_list[key]
        }
      }
      if (this.ischange) {
        this.mediaForm.media_county = '0'
      }
      this.ischange = true
    }
  }
}
</script>

<style scoped>
.tool{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.el-table{
width: 100%;
font-size:13px;
margin-top: 10px;
}
.el-link{
    margin-right: 10px;
    font-size: 11px;
}
::v-deep .el-dialog{
  --el-bg-color:#000741 !important;
}
::v-deep .el-dialog__header{
  --el-text-color-primary:#1effff;
  --el-text-color-regular:#fff;
  padding: 0px !important;
  background-color: #309CE2;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-dialog__title{
    color: white;
}

::v-deep .el-dialog__headerbtn .el-dialog__close{
    color: white;
} 
</style>
